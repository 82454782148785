import React from 'react';
import ArticleItem from '../ArticleItem/ArticleItem';
import { useTheme } from '../../Contexts/ThemeContext'; 
import './ArticleList.css';
const ArticleList = ({ articles }) => {
    const { theme } = useTheme();
    if (!articles || articles.length === 0) {
        return <div>No articles found.</div>;
    }
    return (
        <div className={`article-list ${theme}`}>
            {articles.map((article) => (
                <ArticleItem key={article.UID} article={article} />
            ))}
        </div>
    );
};

export default ArticleList;
