import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ArticleList from '../ArticleList/ArticleList';

const SharePage = () => {
    const [searchParams] = useSearchParams();
    const encryptedUids  = searchParams.get('data');
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {

        // APIリクエスト
        const fetchArticles = async () => {
            try {
                const apiUrl = process.env.REACT_APP_ENV === 'Development'
                    ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_SHARE_APP}${process.env.REACT_APP_BACK_API_SHARE_RESTORE_APP}`
                    : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_SHARE_APP}${process.env.REACT_APP_BACK_API_SHARE_RESTORE_APP}`;

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ encryptedUids }),
                });

                if (!response.ok) {
                    throw new Error('記事の取得に失敗しました。');
                }

                const data = await response.json();
                setArticles(data.articles); // 記事リストをセット
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [encryptedUids]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="share-page">
            <h1>共有された記事</h1>
            {articles.length > 0 ? (
                <ArticleList articles={articles} />
            ) : (
                <p>共有された記事が見つかりませんでした。</p>
            )}
        </div>
    );
};

export default SharePage;
