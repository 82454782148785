import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '../../Contexts/ThemeContext'; 
import './ArticleDetailPage.css';
import FavoriteButton from '../Items/FavoriteMarker/FavoriteButton';
import HistoryManager from '../Items/HistoryManager/HistoryManager';
import GenreArticleList from '../GenreArticleList/GenreArticleList';
import ProviderArticleList from '../ProviderArticleList/ProviderArticleList';
import ShareModal from '../ShareModal/ShareModal';

/*記事の個別ページ*/
const ArticleDetailPage  = () => {
    const { theme } = useTheme();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { articleId } = useParams();
    //同じジャンルの記事を表示
    const [relatedArticlesByGenre, setRelatedArticlesByGenre] = useState({});
    //同じ提供者の記事を表示
    const [providerArticles, setProviderArticles] = useState([]);
    
    //共有ボタン
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const openShareModal = () => setIsShareModalOpen(true);
    const closeShareModal = () => setIsShareModalOpen(false);

    useEffect(() => {
        //記事情報の取得
        //console.log(`Fetching data for article ID: ${articleId}`);
        // 環境に応じた API URL を設定
        const apiUrl = process.env.REACT_APP_ENV === 'Development' ?
            `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_ARTICLE_DETAIL}/${articleId}`:
            `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_ARTICLE_DETAIL}/${articleId}`;
        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch article');
                }
                return response.json();
            })
            .then(data => {
                //console.log("ArticleDetail:",data);
                setArticle(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, [articleId]); 

    useEffect(() => {   
        // 同じジャンルの記事を取得する
        if (article?.Genres) {
            // ジャンルを配列に変換
            const genres = article.Genres.split(',').map((genre) => genre.trim()).filter((genre) => genre);
            
            // ジャンルがない場合は処理を終了
            if (genres.length === 0) {
                console.warn("No genres found.");
                return;
            }
    
            // 各ジャンルごとにリクエストを作成
            const fetchPromises = genres.map((genre) => {
                const samegenreUrl = process.env.REACT_APP_ENV === 'Development' ?
                    `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_SAME_GENRES}${process.env.REACT_APP_BACK_API_SIDE_DISH_GENRES}/${encodeURIComponent(genre)}` :
                    `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_SAME_GENRES}${process.env.REACT_APP_BACK_API_SIDE_DISH_GENRES}/${encodeURIComponent(genre)}`;
                
                return fetch(samegenreUrl)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Failed to fetch articles for genre: ${genre}`);
                        }
                        return response.json();
                    })
                    .then(data => ({ genre, articles: data })); 
            });
    
            // すべてのリクエストが完了するまで待つ
            Promise.all(fetchPromises)
                .then(results => {
                    const articlesByGenre = {};
                    results.forEach(({ genre, articles }) => {
                        articlesByGenre[genre] = articles;
                    });
                    setRelatedArticlesByGenre(articlesByGenre); // 状態を更新
                })
                .catch((err) => console.error("Error fetching articles by genres:", err));
        }
    }, [article]);

    useEffect(() => {
        // 同じ提供元の記事を取得する
        if (article?.ProviderName) {
            const providerName = article.ProviderName.trim();
            const providerArticlesUrl = process.env.REACT_APP_ENV === 'Development'
                ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_PROVIDER}${process.env.REACT_APP_BACK_API_SIDE_DISH_PROVIDER}/${encodeURIComponent(providerName)}`
                : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_PROVIDER}${process.env.REACT_APP_BACK_API_SIDE_DISH_PROVIDER}/${encodeURIComponent(providerName)}`
    
            fetch(providerArticlesUrl)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch articles from the same provider');
                    }
                    return response.json();
                })
                .then(data => {
                    setProviderArticles(data); 
                })
                .catch(err => console.error("Error fetching provider articles:", err));
        }
    }, [article]);
    
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    const handleAccessClick = () => {
        if (article?.AccessURL) {
            window.open(article.AccessURL, "_blank");
        }
    };
    
    return (
        <div className={`article-detail-container ${theme}`}>
             <HistoryManager article={article} />
             <div className="article-detail-content article-section">
                <h1 className="article-detail-report-name">{article?.ReportName}</h1>
                <p className="article-detail-provider-name">{article?.ProviderName}</p>
                <p className="article-detail-date">{new Date(article?.CreatedDate).toLocaleDateString()}</p>
                <p className="article-detail-genres">{article?.Genres}</p>
                <p className="article-detail-content-text">{article?.Content}</p>
                <div className="article-detail-buttons">
                    {article?.AccessURL ? (
                        <button className="article-detail-access-button" onClick={handleAccessClick}>記事にアクセス</button>
                    ) : (
                        <button className="article-detail-access-button disabled" disabled>URLがありません</button>
                    )}
                    <div className="favorite-share-button-container">
                    <FavoriteButton article={article} />
                    <button className="article-detail-share-button" onClick={openShareModal}>共有</button>
                </div>
            </div>
        </div>

        {/* 関連ジャンルの記事 */}
        <div className="article-detail-related-articles article-section">
            <h2 className="article-detail-section-title">同じジャンルの記事</h2>
            <div className="article-detail-genre-articles-container">
        {Object.entries(relatedArticlesByGenre).map(([genre, articles]) => (
            <div key={genre} className="article-detail-genre-articles-section">
                <h3 className="article-detail-genre-title">{genre}</h3>
                <GenreArticleList articles={articles} genre={genre} />
            </div>
        ))}
    </div>
        </div>

        {/* 同じ提供者の記事 */}
        <div className="article-detail-provider-articles article-section">
            <h2>同じ提供者の記事</h2>
            <ProviderArticleList articles={providerArticles} providerName={article?.ProviderName} />
        </div>

        {/* モーダルの条件付きレンダリング */}
        {isShareModalOpen && <ShareModal onClose={closeShareModal} article={article} />}
        
        </div>
        
    );
};

export default ArticleDetailPage ;
