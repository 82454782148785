import React from 'react';
import GenreArticleItem from '../GenreArticleItem/GenreArticleItem'; // ArticleItem をインポート
import { useTheme } from '../../Contexts/ThemeContext';
import './GenreArticleList.css';

const GenreArticleList = ({ articles, genre }) => {
    const { theme } = useTheme();

    if (!articles || articles.length === 0) {
        return <div>No articles found😢.</div>;
    }

    return (
        <div className={`article-detail-genre-list ${theme}`}>
            {articles.slice(0, 5).map((article, index) => (
                
                    <GenreArticleItem article={article} />
                
            ))}
            <div className="article-detail-read-more">
                <a href={`/genres/${genre}`} target="_blank" rel="noopener noreferrer">
                    Read more...
                </a>
            </div>
        </div>
    );
};

export default GenreArticleList;
