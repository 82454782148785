// src/Component/HistoryList/HistoryList.js
import React, { useEffect, useState } from 'react';
import ArticleList from '../ArticleList/ArticleList';
import './HistoryList.css';

const HistoryList = () => {
    const [history, setHistory] = useState([]);

    useEffect(() => {
        // `localStorage`から履歴データを取得
        const storedHistory = JSON.parse(localStorage.getItem('history') || '[]');
        setHistory(storedHistory);
    }, []);

    return (
        <div className="history-list-container">
            <h2>閲覧履歴一覧</h2>
            <p className="history-description">最大100件まで履歴が保存されます。</p>
            <ArticleList articles={history} />
        </div>
    );
};

export default HistoryList;
