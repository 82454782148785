import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ArticleList from '../ArticleList/ArticleList';
import './SearchResult.css';
const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  
  useEffect(() => {
    if (query) {
        const apiUrl = process.env.REACT_APP_ENV === 'Development'
        ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_SEARCH_ROUTE}${process.env.REACT_APP_BACK_API_SEARCH_URL}${encodeURIComponent(query)}`
        : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_SEARCH_ROUTE}${process.env.REACT_APP_BACK_API_SEARCH_URL}${encodeURIComponent(query)}`;
        //console.log("Search URL:",apiUrl);
        const fetchSearchResults = async () => {
            setLoading(true);
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) throw new Error('Failed to fetch search results');
                const data = await response.json();
                setResults(data);
            } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchSearchResults();
    }
  }, [query]);

  if (loading) {
    return (
      <div className="search-results-container message-only">
        <p className="message loading">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="search-results-container message-only">
        <p className="message error">Error: {error}</p>
      </div>
    );
  }

  if (!results.length) {
    return (
      <div className="search-results-container message-only">
        <p className="message no-results">{`"${query}" に対する検索結果は見つかりませんでした😭`}</p>
      </div>
    );
  }
  return (
    <div className="search-results-container">
      <h2>検索結果一覧</h2>
      <p>{`"${query}" に対する検索結果 (${results.length} 件)`}</p>
      <ArticleList articles={results} />
    </div>
  );
};

export default SearchResults;