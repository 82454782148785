// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: var(--header-background);
    color: var(--text-color);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.mobile-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.mobile-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mobile-nav a {
    text-decoration: none;
    color: inherit;
    padding: 10px;
    text-align: center;
}

.mobile-nav a:hover, .mobile-nav a.active {
    text-decoration: underline;
}

.menu-toggle, .search-toggle {
    background: none;
    border: none;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Header/MobileHeader/MobileHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,0CAA0C;IAC1C,wBAAwB;IACxB,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".mobile-header {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 10px 20px;\n    background-color: var(--header-background);\n    color: var(--text-color);\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n}\n\n.mobile-header-content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n.mobile-nav {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.mobile-nav a {\n    text-decoration: none;\n    color: inherit;\n    padding: 10px;\n    text-align: center;\n}\n\n.mobile-nav a:hover, .mobile-nav a.active {\n    text-decoration: underline;\n}\n\n.menu-toggle, .search-toggle {\n    background: none;\n    border: none;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
