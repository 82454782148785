import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GenreArticleItem.css';  
import FavoriteButton from '../Items/FavoriteMarker/FavoriteButton';

const GenreArticleItem = ({ article }) => {
    const navigate = useNavigate(); 
    const handleArticleClick = () => {
        navigate(`/article/${article.UID}`);
    };

    return (
        <div className="genre-article-item" onClick={handleArticleClick}>
            <div className="genre-article-item-header">
                <span className="text-ellipsis">
                    {article.ProviderName || "提供元不明"}
                </span>
            </div>
            <div className="genre-article-item-title">
                <h3 className="text-ellipsis">
                    {article.ReportName || "タイトル不明"}
                </h3>
            </div>
            <div className="genre-article-item-meta">
                <div className="genre-article-item-genres">
                    <span className="meta-label">記事ジャンル:</span>
                    {article.Genres
                        ? article.Genres.split(',').map((genre, index) => (
                            <span 
                                key={index} 
                                className="text-ellipsis genre-article-item-genre-tag"
                            >
                                {genre.trim()}
                            </span>
                          ))
                        : <span>ジャンル情報なし</span>}
                </div>
                <div className="genre-article-item-dates">
                    <span className="meta-label">作成日:</span>
                    <span>{article.CreatedDate
                        ? new Date(article.CreatedDate).toLocaleDateString()
                        : "作成日不明"}
                    </span>
                </div>
            </div>
            <div className="favorite-share-button-container">
                <FavoriteButton article={article} />
            </div>
        </div>
    );
};

export default GenreArticleItem;
