import React, { memo } from 'react';
import { useTheme } from './Contexts/ThemeContext';
import ResponsiveHeader from './Component/Header/ResponsiceHeader';
import AppRoutes from './App_Routes';
    //<div className={`App ${theme}`}>

const AppContent = memo(() => {
  const { theme } = useTheme(); 
  //console.log('AppContent rendered');
  return (
    <div className={`App ${theme}`}>
      <ResponsiveHeader /> 
      
      <AppRoutes />
    </div>
  );
});
export default AppContent;
