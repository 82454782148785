import React from 'react';
import './ArticleItem.css';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../Contexts/ThemeContext'; 
import FavoriteButton from '../Items/FavoriteMarker/FavoriteButton';
const ArticleItem = React.memo(({ article }) => {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const handleArticleClick = () => {
        navigate(`/article/${article.UID}`);
    };
    return (
        <div className={`article-item ${theme}`} onClick={handleArticleClick}>
            <div className="article-item-header">
                <span className="article-item-provider">{article.ProviderName || "提供元不明"}</span>
            </div>
            <div className="article-item-title">
                <h3>{article.ReportName || "タイトル不明"}</h3>
            </div>
            <div className="article-item-meta">
                <div className="article-item-genres">
                    <span className="meta-label">記事ジャンル:</span>
                    {article.Genres 
                        ? article.Genres.split(',').map((genre, index) => (
                            <span key={index} className="article-item-genre-tag">
                                {genre.trim()}
                            </span>
                          ))
                        : <span>ジャンル情報なし</span>
                    }
                </div>
                <div className="article-item-dates">
                    <span className="meta-label">作成日:</span>
                    <span>
                        {article.CreatedDate 
                            ? new Date(article.CreatedDate).toLocaleDateString()
                            : "作成日不明"}
                    </span>
                </div>
            </div>
            <div className="favorite-share-button-container">
                <FavoriteButton article={article} />
            </div>
        </div>
    );
});

export default ArticleItem;
