import React, { useState } from 'react';
import { useTheme } from '../../../Contexts/ThemeContext';
import { NavLink } from 'react-router-dom';
import './MobileHeader.css';
import LightThemeIcon from '../../../Assets/svg/dark_mode.svg';
import DarkThemeIcon from '../../../Assets/svg/light_mode.svg';


const MobileHeader = () => {
    const { theme } = useTheme();
    const themeIcon = theme === 'light-theme' ? LightThemeIcon : DarkThemeIcon;
    const [isOpen, setIsOpen] = useState(false); // メニューの開閉状態を制御するstate

    return (
        <header className={`mobile-header ${theme}`}>
            <div className="mobile-header-content">
                <button onClick={() => setIsOpen(!isOpen)} className="menu-toggle">
                    <img src={themeIcon} alt="Menu Toggle" />
                </button>
                <div className="logo">Logo</div>
                <button className="search-toggle">
                    {/* Search icon replaced by text */}
                    Search
                </button>
            </div>
            {isOpen && (
                <div className="mobile-nav">
                    <NavLink exact to="/" activeClassName="active" onClick={() => setIsOpen(false)}>
                        Home
                    </NavLink>
                    <NavLink to="/favorites" activeClassName="active" onClick={() => setIsOpen(false)}>
                        Favorites
                    </NavLink>
                    <NavLink to="/history" activeClassName="active" onClick={() => setIsOpen(false)}>
                        History
                    </NavLink>
                </div>
            )}
        </header>
    );
};

export default MobileHeader;
