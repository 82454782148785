import React, { useState, useEffect, useCallback } from 'react';
import MobileHeader from './MobileHeader/MobileHeader';
import DesktopHeader from './DesktopHeader/DesktopHeader';
import { debounce } from 'lodash';

const ResponsiveHeader = React.memo(() => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = useCallback(debounce(() => {
    const currentIsMobile = window.innerWidth < 768;
    if (currentIsMobile !== isMobile) {
      setIsMobile(currentIsMobile);
    }
  }, 200), [isMobile]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  //console.log('ResponsiveHeader re-rendered, isMobile:', isMobile);

  return isMobile ? <MobileHeader /> : <DesktopHeader />;
});

export default ResponsiveHeader;
