import React from 'react';
import ArticleItem from '../ArticleItem/ArticleItem'; 
import { useTheme } from '../../Contexts/ThemeContext';
import './ProviderArticleList.css'; 

const ProviderArticleList = ({ articles, providerName }) => {
    const { theme } = useTheme();

    if (!articles || articles.length === 0) {
        return <div>No articles found for this provider.</div>;
    }

    return (
        <div className={`provider-article-list ${theme}`}>
            {articles.slice(0, 5).map((article) => (
                <ArticleItem key={article.UID} article={article} />
            ))}
            <div className="read-more">
                <a href={`/provider/${encodeURIComponent(providerName)}`} target="_blank" rel="noopener noreferrer">
                    Read more about {providerName}
                </a>
            </div>
        </div>
    );
};

export default ProviderArticleList;