import React, { useState, useEffect } from 'react';
import GenreArticleSection  from '../GenreArticleSection/GenreArticleSection';
import './GenreArticlesComponent.css';

const GenreArticlesComponent = ({ genres }) => {
    const [articlesByGenre, setArticlesByGenre] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!genres || genres.length === 0) {
            setErrors({ ...errors, global: 'No genres selected' });
            //console.log("No genres selected, skipping fetch");
            return;
        }
        setLoading(true);
        //console.log("GenreArticlesComponent:",genres)
        genres.forEach(genre => {
            const apiUrl = process.env.REACT_APP_ENV === 'Development' ?
                `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_ARTICLE_GENRE}/${encodeURIComponent(genre)}` :
                `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_ARTICLE_GENRE}/${encodeURIComponent(genre)}`;

            fetch(apiUrl)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Network response was not ok for genre ${genre}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setArticlesByGenre(prev => ({ ...prev, [genre]: data }));
                    setErrors(prev => ({ ...prev, [genre]: null }));
                })
                .catch(error => {
                    console.error('Fetching error:', error);
                    setErrors(prev => ({ ...prev, [genre]: error.toString() }));
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, [genres]); 

    return (
      <div className="genre-articles-container">
          {loading && <p>Loading...</p>}
          {errors.global && <p>{errors.global}</p>}
          {Object.entries(articlesByGenre).map(([genre, articles]) => (
              <div key={genre} className="genre-section">
                  <h2>{genre}</h2>
                  {errors[genre] && <p>Error: {errors[genre]}</p>}
                  <GenreArticleSection articles={articles} genre={genre} />
              </div>
          ))}
      </div>
  );
};

export default GenreArticlesComponent;
