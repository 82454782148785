const genresData = {
  'テクノロジー': ['AI',"量子計算", '通信技術', '生産技術', 'サイバーセキュリティ', 'ソフトウェア', 'ナノテクノロジー', 'バイオテクノロジー', '先端材料技術', '医療技術', '宇宙技術'],
  '政策': ['政策', '外交政策', '公共政策', '環境政策', '経済政策', '社会政策', '安全保障政策', '技術政策', '国際協力政策'],
  '経済': ['経済', '地方', '国際経済', '金融', '貿易', 'インフレ', '雇用', '産業別経済', 'グローバル経済', '経済政策'],
  '産業': [
    'エネルギー', 'インフラ', '半導体', '不動産', '美容', '環境', 'モビリティ', '農業', '漁業', '林業', 
    '宇宙', '製造業', '鉱業', '建設業', '情報通信業', '運輸業', '金融業', '医療・福祉', 'サービス業', 
    'M&A', 'スタートアップ', '経営', '観光','教育', 'コンサルティング', '広告', 'リクルート', '知財', 'エンターテインメント'
  ],
  'その他': ['その他']
};

export default genresData;