import React from 'react';
import './AboutUs.css';
import teamsLogo from '../../Assets/svg/teams_logo.png'; 
const AboutUs = () => {
    const contactFormUrl = process.env.REACT_APP_CONTACT_FORM_URL;
    return (
        <div className="about-us-container">
            <h1>このサイトについて</h1>
            <p>
            このウェブサイトは、シンクタンクや公的機関が発行する経済レポートやマーケットレポートを一元的に提供するプラットフォームです。
            </p>
            
            <h2>主な機能</h2>
            <ol>
                <li><strong>Home:</strong>
                    <ul>
                        <li>最新の更新情報を一目で確認</li>
                        <li>カテゴリー別のフィルタリング機能</li>
                    </ul>
                </li>
                <li><strong>記事の検索:</strong>  検索バーにキーワードを入力してエンターキーを押すだけで、該当する記事を検索可能</li>
                <li><strong>お気に入り記事の保存:</strong> 最大50件までの記事をお気に入りに登録して、あとで簡単にアクセス可能</li>
                <li><strong>履歴機能:</strong> 閲覧した記事を自動的に履歴として記録し、再確認が容易</li>
                <li><strong>記事の共有:</strong> 記事のリンクを簡単にコピーして、他の人とシェア可能</li>
            </ol>
            
            <h2>開発者情報</h2>
                <div className="developer-info">
                    <p><strong>provided by:</strong></p>
                    <img src={teamsLogo} alt="Team Logo" />
                </div>
                <></>
            
            <hr />
            <h2>開発支援</h2>
                <div className="support-section">
                    <p>プロジェクトのサポートにご協力いただける方は、以下のリンクからAmazonほしい物リストをご覧ください。</p>
                    <a
                        href={process.env.REACT_APP_AMAZON_WISHLIST_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="amazon-button"
                    >
                    Amazonほしい物リストを見る
                    </a>
            </div>
            <h2>お問い合わせフォーム</h2>
            <div className="contact-form">
                <iframe
                    src={contactFormUrl}
                    width="640"
                    height="936"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title="Contact Form"
                >
                    読み込んでいます…
                </iframe>
            </div>
        </div>
    );
};

export default AboutUs;
