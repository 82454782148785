// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GenreArticlesComponent.css */
.genre-articles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* 適切な間隔を保つ */
    padding: 20px; /* コンテナにもパディングを設定して余白を確保 */
    width: 80%;
}

.genre-section {
    flex: 1 1 25%; /* 最大で4つまで一行に並ぶように設定 */
    min-width: 250px; /* 各セクションの最小幅を設定 */
    max-width: calc(25% - 20px); /* gapのサイズを考慮した最大幅 */
}
`, "",{"version":3,"sources":["webpack://./src/Component/GenreArticleComponent/GenreArticlesComponent.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;IACI,aAAa;IACb,eAAe;IACf,SAAS,EAAE,aAAa;IACxB,aAAa,EAAE,0BAA0B;IACzC,UAAU;AACd;;AAEA;IACI,aAAa,EAAE,sBAAsB;IACrC,gBAAgB,EAAE,kBAAkB;IACpC,2BAA2B,EAAE,oBAAoB;AACrD","sourcesContent":["/* GenreArticlesComponent.css */\n.genre-articles-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px; /* 適切な間隔を保つ */\n    padding: 20px; /* コンテナにもパディングを設定して余白を確保 */\n    width: 80%;\n}\n\n.genre-section {\n    flex: 1 1 25%; /* 最大で4つまで一行に並ぶように設定 */\n    min-width: 250px; /* 各セクションの最小幅を設定 */\n    max-width: calc(25% - 20px); /* gapのサイズを考慮した最大幅 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
