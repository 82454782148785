// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 全体リストのコンテナ */
.article-detail-genre-list {
    display: flex;
    flex-direction: column; /* 縦並び */
    gap: 20px; /* 各アイテム間のスペース */
    width: 100%; /* 横幅全体を使用 */
    padding: 20px; /* 内部余白 */
    background-color: var(--content-background);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* ボックスサイズを調整 */
}



.article-detail-genre-item:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* 「Read More」リンクのスタイル */
.article-detail-read-more {
    text-align: center;
    margin-top: 20px;
}

.article-detail-read-more a {
    color: var(--link-color);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.article-detail-read-more a:hover {
    color: var(--link-hover-color);
}
`, "",{"version":3,"sources":["webpack://./src/Component/GenreArticleList/GenreArticleList.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,sBAAsB,EAAE,QAAQ;IAChC,SAAS,EAAE,gBAAgB;IAC3B,WAAW,EAAE,YAAY;IACzB,aAAa,EAAE,SAAS;IACxB,2CAA2C;IAC3C,kBAAkB;IAClB,wCAAwC;IACxC,sBAAsB,EAAE,eAAe;AAC3C;;;;AAIA;IACI,2BAA2B;IAC3B,yCAAyC;AAC7C;;AAEA,wBAAwB;AACxB;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,qBAAqB;IACrB,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":["/* 全体リストのコンテナ */\n.article-detail-genre-list {\n    display: flex;\n    flex-direction: column; /* 縦並び */\n    gap: 20px; /* 各アイテム間のスペース */\n    width: 100%; /* 横幅全体を使用 */\n    padding: 20px; /* 内部余白 */\n    background-color: var(--content-background);\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    box-sizing: border-box; /* ボックスサイズを調整 */\n}\n\n\n\n.article-detail-genre-item:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);\n}\n\n/* 「Read More」リンクのスタイル */\n.article-detail-read-more {\n    text-align: center;\n    margin-top: 20px;\n}\n\n.article-detail-read-more a {\n    color: var(--link-color);\n    text-decoration: none;\n    font-weight: bold;\n    transition: color 0.3s ease;\n}\n\n.article-detail-read-more a:hover {\n    color: var(--link-hover-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
