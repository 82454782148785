// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FavoriteButtonのベーススタイル */
.favorite-button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    transition: transform 0.2s ease;
}

/* アイコンのデフォルトスタイル */
.favorite-button img {
    width: 48px; /* サイズを大きく */
    height: 48px;
    filter: grayscale(100%); /* お気に入りでない場合はグレースケール */
    border-radius: 4px; /* 少し角を丸くする */
}

/* お気に入り登録時のスタイル */
.favorite-button.active img {
    filter: brightness(0) saturate(100%) invert(62%) sepia(85%) saturate(5476%) hue-rotate(359deg) brightness(96%) contrast(105%); /* ゴールド色 */
}

/* ホバー時の拡大効果 */
.favorite-button:hover {
    transform: scale(1.1);
}
`, "",{"version":3,"sources":["webpack://./src/Component/Items/FavoriteMarker/FavoriteButton.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,+BAA+B;AACnC;;AAEA,mBAAmB;AACnB;IACI,WAAW,EAAE,YAAY;IACzB,YAAY;IACZ,uBAAuB,EAAE,uBAAuB;IAChD,kBAAkB,EAAE,aAAa;AACrC;;AAEA,kBAAkB;AAClB;IACI,6HAA6H,EAAE,UAAU;AAC7I;;AAEA,cAAc;AACd;IACI,qBAAqB;AACzB","sourcesContent":["/* FavoriteButtonのベーススタイル */\n.favorite-button {\n    border: none;\n    background: none;\n    cursor: pointer;\n    padding: 5px;\n    transition: transform 0.2s ease;\n}\n\n/* アイコンのデフォルトスタイル */\n.favorite-button img {\n    width: 48px; /* サイズを大きく */\n    height: 48px;\n    filter: grayscale(100%); /* お気に入りでない場合はグレースケール */\n    border-radius: 4px; /* 少し角を丸くする */\n}\n\n/* お気に入り登録時のスタイル */\n.favorite-button.active img {\n    filter: brightness(0) saturate(100%) invert(62%) sepia(85%) saturate(5476%) hue-rotate(359deg) brightness(96%) contrast(105%); /* ゴールド色 */\n}\n\n/* ホバー時の拡大効果 */\n.favorite-button:hover {\n    transform: scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
