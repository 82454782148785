// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Component/GenreArticleSection/GenreArticleSection.css */
.genre-article-section {
    display: flex;        /* フレックスボックスレイアウトを有効にする */
    flex-wrap: wrap;      /* アイテムが次の行に折り返すことを許可 */
    gap: 20px;            /* カードの間にスペースを設ける */
    justify-content: flex-start; /* アイテムをコンテナの開始点に揃える */
    padding: 20px;        /* コンテナの周囲にパディングを追加 */
  }
  
  .article-card {
    flex: 1 1 200px;      /* カードが伸縮するが、最小幅は200px */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* 軽い影で立体感を出す */
    border: 1px solid var(--border-color); /* ボーダーカラーを変数で指定 */
    padding: 15px;        /* 各カード内のパディング */
    background-color: var(--content-background); /* 背景色をテーマ変数で指定 */
    color: var(--text-color); /* テキストカラーをテーマ変数で指定 */
  }
  
  @media (max-width: 600px) {
    .genre-article-section {
      flex-direction: column; /* 画面が狭い場合はカードを縦並びにする */
    }
    .article-card {
      flex-basis: 100%;   /* 画面が狭い場合は各カードを全幅にする */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/GenreArticleSection/GenreArticleSection.css"],"names":[],"mappings":"AAAA,8DAA8D;AAC9D;IACI,aAAa,SAAS,yBAAyB;IAC/C,eAAe,OAAO,uBAAuB;IAC7C,SAAS,aAAa,mBAAmB;IACzC,2BAA2B,EAAE,sBAAsB;IACnD,aAAa,SAAS,qBAAqB;EAC7C;;EAEA;IACE,eAAe,OAAO,wBAAwB;IAC9C,qCAAqC,EAAE,eAAe;IACtD,qCAAqC,EAAE,kBAAkB;IACzD,aAAa,SAAS,gBAAgB;IACtC,2CAA2C,EAAE,iBAAiB;IAC9D,wBAAwB,EAAE,qBAAqB;EACjD;;EAEA;IACE;MACE,sBAAsB,EAAE,uBAAuB;IACjD;IACA;MACE,gBAAgB,IAAI,uBAAuB;IAC7C;EACF","sourcesContent":["/* src/Component/GenreArticleSection/GenreArticleSection.css */\n.genre-article-section {\n    display: flex;        /* フレックスボックスレイアウトを有効にする */\n    flex-wrap: wrap;      /* アイテムが次の行に折り返すことを許可 */\n    gap: 20px;            /* カードの間にスペースを設ける */\n    justify-content: flex-start; /* アイテムをコンテナの開始点に揃える */\n    padding: 20px;        /* コンテナの周囲にパディングを追加 */\n  }\n  \n  .article-card {\n    flex: 1 1 200px;      /* カードが伸縮するが、最小幅は200px */\n    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* 軽い影で立体感を出す */\n    border: 1px solid var(--border-color); /* ボーダーカラーを変数で指定 */\n    padding: 15px;        /* 各カード内のパディング */\n    background-color: var(--content-background); /* 背景色をテーマ変数で指定 */\n    color: var(--text-color); /* テキストカラーをテーマ変数で指定 */\n  }\n  \n  @media (max-width: 600px) {\n    .genre-article-section {\n      flex-direction: column; /* 画面が狭い場合はカードを縦並びにする */\n    }\n    .article-card {\n      flex-basis: 100%;   /* 画面が狭い場合は各カードを全幅にする */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
