import React, { createContext, useContext, useState, useMemo , useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light-theme');
  useEffect(() => {
    console.log(`Theme changed to: ${theme}`); // テーマ変更時にログを出力
  }, [theme]);
  const toggleTheme = () => {
    setTheme(currentTheme => currentTheme === 'light-theme' ? 'dark-theme' : 'light-theme');
  };

  const contextValue = useMemo(() => ({
    theme,
    toggleTheme
  }), [theme]); 

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
