import React from 'react';
import GenreArticleList from '../GenreArticleList/GenreArticleList';
import './GenreArticleSection.css';

const GenreArticleSection = ({ genre, articles }) => {
    // articlesが空の配列または未定義の場合にメッセージを表示
    if (!articles || articles.length === 0) {
        return <div className="no-articles">Sorry, no articles found for {genre}😢.</div>;
    }

    return (
        <div className="genre-article-section">
            <GenreArticleList articles={articles} genre={genre} />
        </div>
    );
};

export default GenreArticleSection;
