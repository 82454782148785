import React , { useState } from 'react'; 
import { useTheme } from '../../../Contexts/ThemeContext';
import { NavLink,useNavigate  } from 'react-router-dom';
import './DesktopHeader.css';
import LightThemeIcon from '../../../Assets/svg/dark_mode.svg';
import DarkThemeIcon from '../../../Assets/svg/light_mode.svg';
import ShareModal from '../../ShareModal/ShareModal';

const DesktopHeader = () => {
  const { theme, toggleTheme } = useTheme();
  const themeIcon = theme === 'light-theme' ? LightThemeIcon : DarkThemeIcon;
  const [searchQuery, setSearchQuery] = useState('');
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter' && searchQuery.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchQuery.trim())}`);
    }
  };
  const toggleShareModal = () => {
    setShareModalOpen((prev) => !prev); 
  };
  const handleLogoClick = () => {
    navigate('/'); 
  };
  return (
    <header className="header">
      <div className="header-upper">
        <div className="logo" onClick={handleLogoClick}>
          <span className="logo-main">Echo</span>
          <span className="logo-highlight">Hub</span>
        </div>
        <div className="search-bar">
          <input 
            type="text" 
            placeholder="Search..." 
            value={searchQuery} 
            onChange={handleSearchInput} 
            onKeyDown={handleSearchKeyDown} 
          />
        </div>
        <button onClick={toggleTheme} className="theme-toggle">
          <img src={themeIcon} alt="Toggle Theme" />
        </button> 
        <button onClick={toggleShareModal} className="header-share-button">共有</button> {/* 共有ボタン */}
      </div>
      <div className="header-lower">
        <nav>
          <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>Home</NavLink>
          <NavLink to="/favorites" className={({ isActive }) => isActive ? "active" : ""}>お気に入り</NavLink>
          <NavLink to="/history" className={({ isActive }) => isActive ? "active" : ""}>履歴</NavLink>
          <NavLink to="/aboutus" className={({ isActive }) => isActive ? "active" : ""}>このサイトについて</NavLink>
        </nav> 
      </div>
      {isShareModalOpen && <ShareModal onClose={toggleShareModal} />} {/* モーダル表示 */}
    </header>
  );
};

export default DesktopHeader;
