// src/Component/FavoriteList/FavoriteList.js
import React, { useEffect, useState } from 'react';
import ArticleList from '../ArticleList/ArticleList';
import { useTheme } from '../../Contexts/ThemeContext'; 
import './FavoriteList.css';
const FavoriteList = () => {
    const [favorites, setFavorites] = useState([]);
    const { theme } = useTheme(); 
    useEffect(() => {
        // `localStorage`からお気に入りを取得
        const storedFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
        setFavorites(storedFavorites);
    }, []);

    return (
        <div className={`favorite-list-container ${theme}`}>
            <h2>お気に入り一覧</h2>
            <p className="favorite-description">最大50件までお気に入り登録できます。</p>
            <ArticleList articles={favorites} />
        </div>
    );
};

export default FavoriteList;
