import React, { useState, useEffect, useCallback } from 'react';
import ArticleList from '../ArticleList/ArticleList';
import { useParams } from 'react-router-dom';
import './Genrepage.css';
const GenresPage = () => {
  const { genre } = useParams();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  const fetchArticles = useCallback(async () => {
    if (loading || !hasMore) return; // ロード中または取得する記事がない場合は終了
    setLoading(true);
    setError(null);

    try {
      const baseApiUrl =
        process.env.REACT_APP_ENV === 'Development'
          ? process.env.REACT_APP_BACK_API_DEV_URL
          : process.env.REACT_APP_API_PROD_URL;

      const apiUrl = `${baseApiUrl}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_RECENTLY_ARTICLE_GENRE}/${encodeURIComponent(
        genre
      )}?page=${page}`;

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch genre articles');
      }

      const data = await response.json();

      // 重複のない記事を追加
      const uniqueArticles = Array.from(
        new Map([...articles, ...data].map((article) => [article.UID, article])).values()
      );

      setArticles(uniqueArticles);
      setHasMore(data.length === 30); // データが30件未満の場合、次のページは存在しない
    } catch (error) {
      //console.error('Error fetching articles:', error);
      setError('記事の取得に失敗しました。時間をおいて、もう一度お試しください。');
    } finally {
      setLoading(false);
    }
  }, [genre, page, hasMore, loading, articles]);

  // 初回ロード時またはページが変更されたときにデータを取得
  useEffect(() => {
    fetchArticles();
  }, [page]);

  // ページを進める関数
  const loadMoreArticles = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="genres-page">
      <h1>{genre} の記事</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* エラー表示 */}
      <ArticleList articles={articles} />
      <div>
        {loading && <p>Loading...</p>}
        {!loading && hasMore && (
          <div className="Genre-page-button-container">
            <button
              className="Genre-page-button"
              onClick={loadMoreArticles} // 修正: onClickにloadMoreArticlesを設定
              disabled={loading} // 修正: ボタンをロード中は無効化
            >
              Read more
            </button>
          </div>
        )}
        {!hasMore && <p>これ以上の記事はありません。</p>}
      </div>
    </div>
  );
};

export default GenresPage;
