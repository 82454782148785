
import React, { useState, useEffect } from 'react';
import bookmarkIcon from '../../../Assets/svg/bookmark.svg';
import './FavoriteButton.css'
const FavoriteButton = ({ article }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
      const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
      setIsFavorite(favorites.some(item => item.UID === article.UID));
  }, [article.UID]);

  const toggleFavorite = () => {
      let favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
      if (isFavorite) {
          favorites = favorites.filter(item => item.UID !== article.UID);
          setIsFavorite(false);
          //console.log(`お気に入り解除: ${article.ReportName}`);
      } else {
          if (favorites.length >= 50) favorites.shift();
          favorites.push({
              UID: article.UID,
              ReportName: article.ReportName,
              ProviderName: article.ProviderName,
              Genres: article.Genres,
              CreatedDate: article.CreatedDate,
          });
          setIsFavorite(true);
          //console.log(`お気に入り登録: ${article.ReportName}`);
      }
      localStorage.setItem('favorites', JSON.stringify(favorites));
  };

  return (
      <button 
          className={`favorite-button ${isFavorite ? 'active' : ''}`} 
          onClick={(e) => {
              e.stopPropagation();
              toggleFavorite();
          }}
      >
          <img src={bookmarkIcon} alt="お気に入り" />
      </button>
  );
};

export default FavoriteButton;
