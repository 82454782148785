import { useEffect } from 'react';
//履歴機能
const saveHistory = (article) => {
    const history = JSON.parse(localStorage.getItem('history') || '[]');
    const newEntry = {
        UID: article.UID,
        ReportName: article.ReportName,
        ProviderName: article.ProviderName,
        Genres: article.Genres,
        CreatedDate: article.CreatedDate,
    };
    
    const existingIndex = history.findIndex(item => item.UID === article.UID);
    if (existingIndex === -1) {
        if (history.length >= 100) history.shift();
        history.push(newEntry);
        localStorage.setItem('history', JSON.stringify(history));
    }
};

const HistoryManager = ({ article }) => {
    useEffect(() => {
        if (article) saveHistory(article);
    }, [article]);

    return null;
};

export default HistoryManager;