// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-list {
    display: grid;
    grid-template-columns: 1fr; /* 1列のレイアウト */
    gap: 5px; /* アイテム間のスペース */
    padding: 5px;
    max-width: 1200px; /* コンテナの最大幅 */
    margin: auto; /* 中央揃え */
}

.article-item {
    border: 1px solid #ddd;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.article-item:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}`, "",{"version":3,"sources":["webpack://./src/Component/ArticleList/ArticleList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B,EAAE,aAAa;IACzC,QAAQ,EAAE,eAAe;IACzB,YAAY;IACZ,iBAAiB,EAAE,aAAa;IAChC,YAAY,EAAE,SAAS;AAC3B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,qCAAqC;AACzC;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".article-list {\n    display: grid;\n    grid-template-columns: 1fr; /* 1列のレイアウト */\n    gap: 5px; /* アイテム間のスペース */\n    padding: 5px;\n    max-width: 1200px; /* コンテナの最大幅 */\n    margin: auto; /* 中央揃え */\n}\n\n.article-item {\n    border: 1px solid #ddd;\n    padding: 5px;\n    background-color: #fff;\n    box-shadow: 0 2px 6px rgba(0,0,0,0.1);\n}\n\n.article-item:hover {\n    box-shadow: 0 4px 12px rgba(0,0,0,0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
