// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-list-container {
    padding: 20px;
    background-color: var(--background);
    color: var(--text-color);
    box-sizing: border-box;
    overflow-y: auto;
}

.history-list-container h2 {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1.5em;
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
    text-align: center;
}

.history-description {
    font-size: 1em;
    color: var(--text-color);
    margin-bottom: 20px;
    text-align: center;
}

`, "",{"version":3,"sources":["webpack://./src/Component/HistoryList/HistoryList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mCAAmC;IACnC,wBAAwB;IACxB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,wBAAwB;IACxB,4CAA4C;IAC5C,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,wBAAwB;IACxB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".history-list-container {\n    padding: 20px;\n    background-color: var(--background);\n    color: var(--text-color);\n    box-sizing: border-box;\n    overflow-y: auto;\n}\n\n.history-list-container h2 {\n    margin-bottom: 15px;\n    padding: 10px;\n    font-size: 1.5em;\n    color: var(--text-color);\n    border-bottom: 1px solid var(--border-color);\n    text-align: center;\n}\n\n.history-description {\n    font-size: 1em;\n    color: var(--text-color);\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
