import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ArticleList from '../ArticleList/ArticleList';
import './ProviderArticlesPage.css';

const ProviderArticlesPage = () => {
  const { providerName } = useParams();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const decodedProviderName = decodeURIComponent(providerName);

  // 記事データを取得
  const fetchArticles = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);

    try {
      const providerArticlesUrl = process.env.REACT_APP_ENV === 'Development'
        ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_PROVIDER}${process.env.REACT_APP_BACK_API_PROVIDER_LIST}/${encodeURIComponent(providerName)}?page=${page}`
        : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_PROVIDER}${process.env.REACT_APP_BACK_API_PROVIDER_LIST}/${encodeURIComponent(providerName)}?page=${page}`;

      const response = await fetch(providerArticlesUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch provider articles');
      }

      const data = await response.json();

      // 重複を削除してユニークな記事リストを生成
      const uniqueArticles = Array.from(
        new Map([...articles, ...data].map((article) => [article.UID, article])).values()
      );

      setArticles(uniqueArticles);
      setHasMore(data.length === 30); // データが30件未満の場合、これ以上データがない
    } catch (error) {
      console.error('Error fetching provider articles:', error);
    } finally {
      setLoading(false);
    }
  }, [providerName, page, hasMore, loading, articles]);

  // 初回ロードとページ変更時にデータ取得
  useEffect(() => {
    fetchArticles();
  }, [page]);

  // 次のページをロード
  const loadMoreArticles = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="provider-articles-page">
      <h1>{decodedProviderName} の記事一覧</h1>
      <ArticleList articles={articles} />
      <div className="provider-articles-button-container">
        {loading && <p>Loading...</p>}
        {!loading && hasMore && (
          <button className="provider-articles-load-more-button" onClick={loadMoreArticles}>
            Read more
          </button>
        )}
        {!hasMore && <p>これ以上の記事はありません。</p>}
      </div>
    </div>
  );
};

export default ProviderArticlesPage;